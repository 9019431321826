import { useEffect } from "react";

type DelegationHandler = (event: Event, targetElement: Element) => void;

export const useBodyEventDelegation = (
  selector: string,
  handler: DelegationHandler
) => {
  useEffect(() => {
    function delegationHandler(event: Event) {
      const targetElement = (event.target as Element).closest(selector);
      if (targetElement) {
        handler(event, targetElement);
      }
    }

    document.body.addEventListener("click", delegationHandler);

    return () => {
      document.body.removeEventListener("click", delegationHandler);
    };
  }, [selector, handler]);
};
