import { css } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../theme";

const NavigationItemsContainer = styled.ul`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    @media ${theme.devices.desktop} {
      display: grid;
      grid-auto-rows: auto;
      grid-gap: 24px;
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;
const NavigationItemsChild = styled.li`
  ${() => css`
    /* display: flex;
    justify-content: center; */
    text-align: center;
    width: 100%;
    @media ${theme.devices.desktop} {
      width: fit-content;
    }
    /* stylelint-disable-next-line selector-max-type */
    :first-child {
      border-top: 2px solid ${theme.colors.neutral200};
      @media ${theme.devices.desktop} {
        border: none;
      }
    }
  `}
`;

const defaultExport = {
  NavigationItemsContainer,
  NavigationItemsChild,
};

export default defaultExport;
