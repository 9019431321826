import { useEffect, createContext, useState, useMemo, useContext } from "react";

interface ZendeskContextType {}

const ZendeskContext = createContext<ZendeskContextType | undefined>(undefined);

export function useZendeskContext() {
  const context = useContext(ZendeskContext);
  if (context === undefined) {
    throw new Error(
      "useZendeskContextt must be used within an AppContextProvider"
    );
  }
  return context;
}

const ZendeskProvider = ({ children }: { children: JSX.Element }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (!scriptLoaded) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`;
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        setScriptLoaded(true);
      });
    }
  }, [scriptLoaded]);

  const value = useMemo(() => ({}), []);

  return (
    <ZendeskContext.Provider value={value}>{children}</ZendeskContext.Provider>
  );
};

export default ZendeskProvider;
