import React, { useState } from "react";

import { NavigationMenu } from "../NavigationMenu";
import { NamedLinkWithChildrenFooter } from "../types";
import Style from "./NavItem.style";

export interface Props {
  navItems: NamedLinkWithChildrenFooter[];
}

const NavItem: React.FC<Props> = React.memo(({ navItems }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Style.NavigationItemsContainer>
      {navItems.map((navItem) => (
        <Style.NavigationItemsChild key={`${navItem.label.trim()}`}>
          <NavigationMenu
            navItem={navItem}
            handleChange={handleChange}
            expanded={expanded}
          />
        </Style.NavigationItemsChild>
      ))}
    </Style.NavigationItemsContainer>
  );
});

export default NavItem;
