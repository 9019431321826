import { useEffect, useMemo, useState } from "react";

const breakpoint = {
  mobile: 600,
  tablet: 1024,
  small_desktop: 1366,
  big_desktop: 1920,
};

const meidaQueries = {
  mobile: `(max-width: ${breakpoint.mobile}px)`,
  tablet: `(min-width: ${breakpoint.mobile + 1}px) and (max-width: ${
    breakpoint.tablet
  }px)`,
  mobile_and_tablet: `(max-width: ${breakpoint.tablet}px)`,
  tablet_and_desktop: `(min-width: ${breakpoint.mobile + 1}px)`,
  desktop: `(min-width: ${breakpoint.tablet + 1}px)`,
  small_desktop: `(min-width: ${breakpoint.small_desktop + 1}px)`,
  big_desktop: `(min-width: ${breakpoint.big_desktop + 1}px)`,
};

type MediaQueries = keyof typeof meidaQueries;
function useMediaQuery(query: MediaQueries): boolean {
  const [matches, setMatches] = useState(false);

  const mediaQuery = useMemo(() => meidaQueries[query], [query]);

  useEffect(() => {
    const mediaQueryWatcher = window.matchMedia(mediaQuery);

    function updateMatches(event: MediaQueryListEvent) {
      setMatches(event.matches);
    }

    // Initial check
    setMatches(mediaQueryWatcher.matches);

    // Add listener for future updates
    mediaQueryWatcher.addEventListener("change", updateMatches);

    // Clean up the listener when the component unmounts
    return () => {
      mediaQueryWatcher.removeEventListener("change", updateMatches);
    };
  }, [query, mediaQuery]);

  return matches;
}

export default useMediaQuery;
