export const theme = {
  colors: {
    primary100: "#FFF6CC",
    primary500: "#FFD100",
    primary600: "#EEC300",
    secondary400: "#EBF2F2",
    secondary500: "#DFE8E8",
    neutral0: "#FFFFFF",
    neutral100: "#F2F2F2",
    neutral200: "#EAEAEA",
    neutral300: "#D9D9D9",
    neutral400: "#BBBBBB",
    neutral500: "#757575",
    neutral600: "#353535",
    neutral800: "#1F1F1F",
    neutral900: "#0D0D0D",
    alert100: "#FFEBEB",
    alert500: "#D70D0D",
    validate100: "#E6F8ED",
    validate500: "#05702F",
    alternative1: "#9DC8EA",
    alternative2: "#ACDF8D",
    alternative3: "#FFC98A",
  },
  devices: {
    mobile: "(max-width: 699px)",
    nonMobile: "(min-width: 700px)",
    desktop: "(min-width: 1023px)",
    mediumDevice: "(min-width: 700px) and (max-width: 1023px)",
    largeDevice: "(min-width: 1023px) and (max-width: 1299px)",
    xlargeDevice: "(min-width: 1300px)",
    wideDevice: "(min-width: 1600px)",
  },
  maxWidth: {
    small: "700px",
    normal: "1072px",
    large: "1288px",
    wide: "1288px",
  },
};
export type Theme = typeof theme;
