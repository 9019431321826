import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  css,
} from "@mui/material";
import { theme } from "../theme";
import styled from "@emotion/styled";

export const AccordionContainer = styled(Accordion)(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: black;
    border-bottom: 2px solid ${theme.colors.neutral200};
    box-shadow: none;
    & .MuiAccordionSummary-expandIconWrapper {
      color: white;
    }
    & .Mui-disabled {
      opacity: 1;
    }
    @media ${theme.devices.desktop} {
      align-items: flex-start;
      border: none;
    }
  `
);

export const AccordionSummaryContainer = styled(AccordionSummary)(
  () => css`
    display: inline-flex;
    justify-content: space-between;
    width: fit-content;
    padding: 0;
    color: white;
  `
);

export const AccordionDetailsContainer = styled(AccordionDetails)(
  () => css`
    padding: 0;
    text-align: center;
    @media ${theme.devices.desktop} {
      text-align: left;
    }
  `
);

export const NavLinksList = styled.ul`
  ${() => css`
    margin: 0;
    padding: 0;
    list-style-type: none;
  `}
`;

export const LinkContainer = styled.a`
  ${() => css`
    text-decoration: inherit;
    color: white;
    display: inline-flex;
    justify-items: center;
    padding: 4px 0;
  `}
`;

const defaultExport = {
  AccordionDetailsContainer,
  AccordionSummaryContainer,
  AccordionContainer,
  LinkContainer,
};

export default defaultExport;
