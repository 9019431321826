import React, { useCallback, useState } from "react";
import { HeaderHelp } from "./components/Header";
import styles from "./App.module.scss";
import Footer from "./components/Footer/Footer";
import "./styles/index.scss";
import ZendeskProvider from "./ZendeskProvider";
import { useBodyEventDelegation } from "./hooks/useBodyEventDelegation";
import { Modal } from "@roole/components-library";
import { useMatchMedia } from "../src/hooks/useMatchMedia";
import cssVariables from "../src/styles/variables.module.scss";


function App() {
  const [faqLoaded, setFaqLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const faqRef = useCallback((node: HTMLScriptElement) => {
    if (node) {
      if (window._ST_FAQ === undefined) {
        const _ST_FAQ_script = document.createElement("script");
        _ST_FAQ_script.async = true;
        _ST_FAQ_script.src = process.env.REACT_APP_SMART_TRIBUNE_URL!;
        document.getElementsByTagName("head")[0].appendChild(_ST_FAQ_script);
      }

      window.addEventListener("STFAQLoaded", function (i: any) {
        i.detail.init({
          kbId: 404,
          locale: "fr",
          cookieOptin: false,
          filters: { thematics: [], tags: [] },
        });
        setFaqLoaded(true); // Set faqLoaded to true when the event is fired
      });
    }
  }, []);

  useBodyEventDelegation("#st-faq", (event, targetElement) => {
    const openChatBtn = (event.target as Element).closest(
      ".contact-button-name-contact-livechat-1079"
    );
    if (openChatBtn && window.zE?.activate) {
      window.zE.activate({ hideOnClose: true });
    }
  });

  const handleClickLogo = () => {
    setShowModal(true);
  }

  // Extract hideHeaderFooter parameter from URL
  const queryParams = new URLSearchParams(window.location.search);
  const hideHeaderFooter = queryParams.get("hideHeaderFooter") === "true";
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const isTablet = useMatchMedia(cssVariables.breakpointTablet);

  const style = isMobile ? { paddingTop: 75, paddingLeft: 20, paddingRight: 20 } : isTablet ? {paddingTop: 76, paddingLeft: 20} : { paddingTop: 103, paddingLeft: 24 }

  return (
    <ZendeskProvider>
      <>
        {!hideHeaderFooter && <HeaderHelp handleClickLogo={handleClickLogo} />}
        <Modal
          title="Que voulez-vous faire ?"
          content={
            <>
              <p>Vous êtes sur le point de quitter le centre d’aide Roole.</p>
            </>
          }
          button={{
            url: "",
            label: "Accéder au site roole.fr",
            handleButton: () => {
              window.location.href = "https://roole.fr/";
            },
          }}
          secondaryButton={{
            url: '/',
            label: "Revenir au centre d’aide",
            handleButton: () => {},
          }}
          isOpen={showModal}
          handleClose={() => setShowModal(false)}
          isStart={true}
          withBlur={false}
          style={style}
        />
          <div id="st-faq" className={styles.faq} ref={faqRef as any}></div>
        {faqLoaded ? <>{!hideHeaderFooter && <Footer />}</> : null}
      </>
    </ZendeskProvider>
  );
}

export default App;
