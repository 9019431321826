import { Icon, Text } from "@roole/components-library";
import Style from "./Footer.style";
import { NamedLinkWithChildrenFooter } from "./types";
import { NavItem } from "./NavItem";
import { URLS } from "../../config/urls";
import { ReactComponent as Facebook } from "../../images/icons/facebook.svg";
import { ReactComponent as YouTube } from "../../images/icons/youtube.svg";
import { ReactComponent as Instagram } from "../../images/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "../../images/icons/linkedin.svg";
import { ReactComponent as Twitter } from "../../images/icons/twitter.svg";
import { ReactComponent as Tiktok } from "../../images/icons/tiktok.svg";
import { ReactComponent as HelpIcon } from "../../images/icons/help.svg";
import AvisVerifiesWidget from "./AvisVerifies";
import { Button } from "../Button";

export enum appRoutes {
  WETRAK_IOS = `https://apps.apple.com/fr/app/wetrak/id990229561`,
  WETRAK_ANDROID = "https://play.google.com/store/apps/details?id=com.wetrak.wetrak&hl=fr_FR",
  ROOLE_MAP_IOS = "https://apps.apple.com/app/apple-store/id1621923838?pt=1722761&ct=Site%20Roole_CTA&mt=8",
  ROOLE_MAP_ANDROID = "https://play.google.com/store/apps/details?id=com.roole.roolemap&referrer=utm_source%3DsiteRoole%26utm_medium%3DCTA%26anid%3Dadmob",
  ROOLE_PREMIUM_IOS = "https://apps.apple.com/fr/app/roole-connect/id1605740783",
  ROOLE_PREMIUM_ANDROID = "https://play.google.com/store/apps/details?id=com.roole.rooleconnect&hl=fr",
}

const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());

const navItems: NamedLinkWithChildrenFooter[] = [
  {
    label: "À propos de Roole",
    children: [
      {
        label: "Charte de transparence",
        url: "https://www.roole.fr/charte-de-transparence",
      },
      { label: "Contactez-nous", url: "https://www.roole.fr/contact" },
      { label: "Nous rejoindre", url: "https://www.roole.fr/nous-rejoindre" },
      { label: "Presse", url: "https://www.roole.fr/presse" },
    ],
  },
  {
    label: "Besoin d’aide ?",
    children: [
      { label: "FAQ", url: "/" },
      {
        label: "Régler votre cotisation",
        url: "https://www.roole.fr/faq/regler-votre-cotisation",
      },
      {
        label: "Déclarer un sinistre",
        url: "https://www.roole.fr/faq/declarer-un-sinistre-et-suivre-votre-indemnisation",
      },
      {
        label: "Suivre une indemnisation",
        url: "https://www.roole.fr/faq/declarer-un-sinistre-et-suivre-votre-indemnisation",
      },
    ],
  },
  {
    label: "Applications",
    children: [
      {
        label: "Roole Premium",
        url: isIos
          ? appRoutes.ROOLE_PREMIUM_IOS
          : appRoutes.ROOLE_PREMIUM_ANDROID,
      },
      {
        label: "Roole Map",
        url: isIos ? appRoutes.ROOLE_MAP_IOS : appRoutes.ROOLE_MAP_ANDROID,
      },
      {
        label: "Wetrak",
        url: isIos ? appRoutes.WETRAK_IOS : appRoutes.WETRAK_ANDROID,
      },
    ],
  },
  {
    label: "Mentions juridiques",
    children: [
      {
        label: "Mentions légales",
        url: "https://www.roole.fr/mentions-legales",
      },
      {
        label: "Charte de confidentialité",
        url: "https://www.roole.fr/charte-de-confidentialite",
      },
      { label: "Cookies", url: "https://www.roole.fr/cookies" },
    ],
  },
];
const headLine = "Roole, le 1er club automobile de France.";
const summary =
  "Nous proposons des services de protection contre le vol, des garanties complémentaires à l'assurance auto principale, un média et des applications pour simplifier la vie des automobilistes.";
const socialHeadline = "Rejoignez-nous";
const credits =
  "Roole est une marque d'IDENTICAR SAS, société de courtage en assurance, immatriculée à l'ORIAS sous le numéro N° 09.052.611 : registre des intermédiaires d'assurance accessible sur https://www.orias.fr.";

const Footer: React.FC = () => {
  const iconStyle = {
    color: "white",
    width: "24px",
    height: "24px",
  };

  const socials = [
    {
      id: "footer-facebook",
      href: URLS.FACEBOOK,
      icon: <Facebook style={iconStyle} />,
    },
    {
      id: "footer-instagram",
      href: URLS.INSTAGRAM,
      icon: <Instagram style={iconStyle} />,
    },
    {
      id: "footer-twitter",
      href: URLS.TWITTER,
      icon: <Twitter style={iconStyle} />,
    },
    {
      id: "footer-linkedin",
      href: URLS.LINKEDIN,
      icon: <LinkedIn style={iconStyle} />,
    },
    {
      id: "footer-youtube",
      href: URLS.YOUTUBE,
      icon: <YouTube style={iconStyle} />,
    },
    {
      id: "footer-tiktok",
      href: URLS.TIKTOK,
      icon: <Tiktok style={iconStyle} />,
    },
  ];

  return (
    <Style.Wrapper>
      <Style.FirstContainer>
        <Style.WhoIsRooleContainer>
          <Style.WhoIsRooleTitleContainer>
            <Text
              textVariant="heading-3"
              style={{
                fontWeight: 600,
              }}
            >
              {headLine}
            </Text>
          </Style.WhoIsRooleTitleContainer>
          <Style.WhoIsRooleSummaryContainer>
            <Text textVariant="ui-1">{summary}</Text>
          </Style.WhoIsRooleSummaryContainer>
          <Style.CallRooleContainer>
            <Button
              icon={
                <span>
                  <HelpIcon />
                </span>
              }
              variant="tertiary"
              onClick={() =>
                (window.location.href = "/")
              }
            >
              Centre d'aide
            </Button>
          </Style.CallRooleContainer>
          <Text textVariant="subheading">{socialHeadline}</Text>
          <Style.SocialContainer>
            {socials.map((social) => (
              <Style.SingleSocialContainer
                href={social.href}
                // aria-label={social.label}
                key={social.id}
              >
                <a
                  id={social.id}
                  href={social.href}
                  // className={styles.socialLink}
                >
                  {social.icon}
                </a>
              </Style.SingleSocialContainer>
            ))}

            {/* {socials.map((social) => (
              <Style.SingleSocialContainer
                
                <Icon variant={social.icon} />
              </Style.SingleSocialContainer>
            ))} */}
          </Style.SocialContainer>
        </Style.WhoIsRooleContainer>
        <Style.BackToTopContainer
          aria-label="haut de page"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <Icon variant="arrow-up" />
        </Style.BackToTopContainer>
        <Style.FooterNavigationContainer>
          <NavItem navItems={navItems} />
        </Style.FooterNavigationContainer>
      </Style.FirstContainer>
      <Style.LastContainer>
        <AvisVerifiesWidget />
        <Style.LastContainerOrias>
          <Text textVariant="ui-2">{credits}</Text>
        </Style.LastContainerOrias>
      </Style.LastContainer>
    </Style.Wrapper>
  );
};
export default Footer;
