import { useRef, useEffect } from "react";

const AvisVerifiesWidget: React.FC = () => {
  const metaRef = useRef<HTMLMetaElement>(null);

  function loadScript() {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://cl.avis-verifies.com/fr/widget4/widget03.min.js";
      script.async = true;

      script.onload = () => resolve();
      script.onerror = () =>
        reject(new Error(`Failed to load script: ${script.src}`));

      metaRef.current?.parentNode?.insertBefore(
        script,
        metaRef.current?.nextSibling
      );
    });
  }

  useEffect(() => {
    loadScript();
  }, []);

  return (
    <div className="col-12 col-md-4 col-lg-4 my-4 avisverifiecontainer">
      <meta
        ref={metaRef}
        className="netreviewsWidget"
        id="netreviewsWidgetNum30350"
        data-jsurl="https://cl.avis-verifies.com/fr/cache/2/d/6/2d6dcad6-e2be-34c4-0d44-2b5c5914df54/widget4/widget03-30350_script.js"
      />
    </div>
  );
};

export default AvisVerifiesWidget;
