import { css } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "./theme";

const Wrapper = styled.div`
  color: white;
  background-color: black;
  padding: 32px 32px;
  @media ${theme.devices.desktop} {
    padding-bottom: 0;
  }
`;

const FirstContainer = styled.div`
  ${() => css`
    /* background: black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${theme.maxWidth.normal};
    margin: 0 auto;
    padding: 0 24px;
    @media ${theme.devices.desktop} {
      flex-direction: row;
      align-items: flex-start;
    }
    @media ${theme.devices.xlargeDevice} {
      padding: 0;
    }
  `}
`;

const WhoIsRooleContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;
    @media ${theme.devices.desktop} {
      flex-shrink: 1.3;
      align-items: flex-start;
    }
  `}
`;

const WhoIsRooleTitleContainer = styled.h2`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding-bottom: 32px;
    text-align: center;
    @media ${theme.devices.desktop} {
      justify-content: left;
      text-align: left;
    }
  `}
`;

const WhoIsRooleSummaryContainer = styled.div`
  ${() => css`
    display: flex;
    width: 90%;
    text-align: center;
    @media ${theme.devices.mediumDevice} {
      width: 100%;
    }
    @media ${theme.devices.desktop} {
      text-align: left;
    }
  `}
`;

const CallRooleContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 90%;
    margin-top: 24px;
    margin-bottom: 40px;
    @media ${theme.devices.desktop} {
      align-items: flex-start;
    }
    /* stylelint-disable-next-line selector-max-type */
    & a,
    button {
      display: flex;
      justify-content: center;
    }
    span {
      margin-right: 4px;
    }
  `}
`;

const SocialContainer = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 320px;
    margin-top: 10px;
    @media ${theme.devices.desktop} {
      justify-content: space-between;
    }
  `}
`;

const SingleSocialContainer = styled.a`
  ${() => css`
    width: 24px;
    cursor: pointer;
    @media ${theme.devices.desktop} {
      width: 32px;
    }
  `}
`;

const BackToTopContainer = styled.button`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;
    order: 2;
    width: 52px;
    height: 52px;
    margin: 40px 0;
    background-color: ${theme.colors.neutral100};
    color: black;
    border-radius: 48px;
    @media ${theme.devices.desktop} {
      flex-shrink: 0;
      order: 3;
      margin: 0;
    }
  `}
`;

const FooterNavigationContainer = styled.div`
  ${() => css`
    order: 3;
    width: 100%;
    @media ${theme.devices.desktop} {
      order: 2;
    }
  `}
`;

const LastContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 80px;
    /* background: black; */
    @media ${theme.devices.nonMobile} {
      flex-direction: row;
      gap: 22px;
      max-width: ${theme.maxWidth.normal};
      margin: 100px auto 0 auto;
      padding: 0 20px 32px 20px;
    }
  `}
`;

const LastContainerOrias = styled.div`
  ${() => css`
    flex: 1 1 0%;
    width: 80%;
    margin-top: 20px;
    color: ${theme.colors.neutral400};
    text-align: center;
    @media ${theme.devices.nonMobile} {
      margin-top: 0;
      text-align: left;
    }
  `}
`;

const defaultExport = {
  Wrapper,
  FirstContainer,
  LastContainer,
  FooterNavigationContainer,
  BackToTopContainer,
  LastContainerOrias,
  WhoIsRooleContainer,
  WhoIsRooleTitleContainer,
  WhoIsRooleSummaryContainer,
  CallRooleContainer,
  SocialContainer,
  SingleSocialContainer,
};

export default defaultExport;
