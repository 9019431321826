import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionDetailsContainer,
  AccordionSummaryContainer,
  AccordionContainer,
  LinkContainer,
  NavLinksList,
} from "./NavigationMenu.style";
import { NamedLinkWithChildrenFooter } from "../types";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Text } from "@roole/components-library";

export interface Props {
  navItem: NamedLinkWithChildrenFooter;

  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  expanded: string | false;
}

const NavigationMenu: React.FC<Props> = ({
  expanded,
  handleChange,
  navItem,
}) => {
  const panelName = navItem.label.replace(" ", "-");
  const isDesktop = useMediaQuery("desktop");

  return (
    <>
      <AccordionContainer
        defaultExpanded={isDesktop ? true : false}
        expanded={isDesktop ? true : expanded === panelName}
        onChange={handleChange(panelName)}
        disableGutters={true}
      >
        <AccordionSummaryContainer
          expandIcon={isDesktop ? "" : <ExpandMoreIcon />}
          aria-controls={`${panelName}-content`}
          id={`${panelName}-header`}
          disabled={isDesktop ? true : false}
        >
          <Text textVariant="ui-1">{navItem.label}</Text>
        </AccordionSummaryContainer>
        <AccordionDetailsContainer>
          <NavLinksList>
            {navItem.children?.map((child) => (
              <li key={`${child.label.trim()}`}>
                <LinkContainer href={child.url}>
                  <Text textVariant="ui-1">{child.label}</Text>
                </LinkContainer>
              </li>
            ))}
          </NavLinksList>
        </AccordionDetailsContainer>
      </AccordionContainer>
    </>
  );
};
export default NavigationMenu;
