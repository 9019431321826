import { ButtonHTMLAttributes, FC, useEffect, useRef } from "react";

import { classNames } from "../../utils/classNames";

import styles from "./Button.module.scss";

export type ButtonProps = {
  variant?: string;
  isSmall?: boolean;
  icon?: React.ReactNode;
  iconAfterText?: boolean;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: FC<ButtonProps> = ({
  variant,
  autoFocus,
  className,
  isSmall = false,
  icon,
  iconAfterText = false,
  children,
  disabled = false,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (autoFocus) {
      buttonRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <button
      ref={buttonRef}
      className={classNames(
        styles.button,
        variant === "tertiary" && styles.tertiary,
        variant === "secondary" && styles.secondary,
        variant === "primary" && styles.primary,
        variant === "outlined-light" && styles.outlinedLight,
        variant === "outlined-dark" && styles.outlinedDark,
        variant === "radio-not-selected" && styles.radioNotSelected,
        isSmall && styles.small,
        icon && styles.icon,
        iconAfterText && icon && styles.iconAfterText,
        disabled && styles.disabled,
        className
      )}
      disabled={disabled}
      {...props}
    >
      {icon} {children}
    </button>
  );
};

export default Button;
