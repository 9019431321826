import { FC } from "react";
import styles from "./Header.module.scss";
import { Button } from "../Button/Button";
import { ReactComponent as Avatar } from "../../images/icons/avatar.svg";
import cssVariables from "../../styles/variables.module.scss";
import { ReactComponent as LogoBig } from "../../images/logo/logo-big.svg";
import { ReactComponent as LogoSmall } from "../../images/logo/logo-small.svg";
import { useMatchMedia } from "../../hooks/useMatchMedia";

type HeaderHelpProps = {
  handleClickLogo?: () => void;
};

export const HeaderHelp: FC<HeaderHelpProps> = ({ handleClickLogo }) => {
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);

  return (
    <div className={styles.Header}>
      {isDesktop ? (
        <LogoBig
          onClick={() => handleClickLogo && handleClickLogo()}
          className={styles.logoRoole}
        />
      ) : (
        <LogoSmall
          onClick={() => handleClickLogo && handleClickLogo()}
          className={styles.logoRoole}
        />
      )}
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            window.location.href = "https://devenirmembre.roole.fr/";
          }}
          variant="outlined-light"
          className={styles.subscribtionButton}
        >
          Souscrire
        </Button>
        {isMobile ? (
          <button
            className={styles.iconButton}
            onClick={() => {
              window.location.href = "https://moncompte.roole.fr/";
            }}
          >
            <Avatar />
          </button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              window.location.href = "https://moncompte.roole.fr/";
            }}
            icon={<Avatar />}
            className={styles.accountButton}
          >
            Mon compte
          </Button>
        )}
      </div>
    </div>
  );
};
